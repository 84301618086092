import urls from "../../constants/urls.js";
import axios from "axios";
import store from "../store.js";
import { useCampaignSettings } from "@/composables/campaign-settings";

const { createSetting, updateSetting, getSetting } = useCampaignSettings();
const SETTING_NAME = "event_page_configuration";

function removeItemFromArray(array, team_id, donation_id) {
  const index = array.findIndex(
    (item) => item.team_id === team_id && item.donation_id === donation_id
  );

  if (index !== -1) {
    array.splice(index, 1);
  }

  return array;
}

const state = {
  eventDonations: [],
  loadingEventDonationsList: false,
  loadingEventSettings: false,
  eventSettingsSchema: {},
  eventSettingsSchemaId: null,
  eventStats: {},
};

const mutations = {
  setLoadingEventDonationsList(state, value) {
    state.loadingEventDonationsList = value;
  },

  setLoadingEventSettings(state, value) {
    state.loadingEventSettings = value;
  },

  setEventDonations(state, data) {
    state.eventDonations = data;
  },

  setEventSettingsSchema(state, data) {
    state.eventSettingsSchema = data;
  },

  setEventSettingsSchemaId(state, id) {
    state.eventSettingsSchemaId = id;
  },

  setEventStats(state, data) {
    state.eventStats = data;
  },
};

const getters = {};

const actions = {
  getEventDonationsList({ commit }, props) {
    commit("setLoadingEventDonationsList", true);
    const orgId = localStorage.getItem("orgId");
    return axios
      .get(urls.eventDonationList(orgId, store.state.dashboard.selectedCampaign), {
        params: {
          ...(props?.params || {}),
          stats: 1,
        },
      })
      .then((response) => {
        let donation = response?.data?.data;
        commit("setEventDonations", donation);
        commit("setEventStats", response?.data?.meta);
      })
      .finally(() => {
        commit("setLoadingEventDonationsList", false);
      });
  },

  setInitialEventSettings({ commit }, props) {
    commit("setEventSettingsSchema", props);
  },

  async saveEventSettings({ state, commit }, props) {
    const orgId = localStorage.getItem("orgId");
    commit("setLoadingEventSettings", true);
    if (state.eventSettingsSchemaId) {
      try {
        const setting = await updateSetting(
          { cid: store.state.dashboard.selectedCampaign, oid: orgId },
          SETTING_NAME,
          state.eventSettingsSchemaId,
          props.form
        );

        if (setting) {
          commit("setEventSettingsSchema", setting.attributes.meta_data);
          return setting;
        }
      } finally {
        commit("setLoadingEventSettings", false);
      }
    } else {
      try {
        const setting = await createSetting(
          { cid: store.state.dashboard.selectedCampaign, oid: orgId },
          SETTING_NAME,
          props.form
        );

        if (setting) {
          commit("setEventSettingsSchema", setting.attributes.meta_data);
          commit("setEventSettingsSchemaId", setting.id);
          return setting;
        }
      } finally {
        commit("setLoadingEventSettings", false);
      }
    }
  },

  async getEventSettings({ commit }) {
    const orgId = localStorage.getItem("orgId");
    commit("setLoadingEventSettings", true);
    try {
      const setting = await getSetting(
        {
          cid: store.state.dashboard.selectedCampaign,
          oid: orgId,
          extraParams: {
            falback_to_meta_dir: 1,
          },
        },
        SETTING_NAME
      );

      if (setting && setting.id !== "0") {
        commit("setEventSettingsSchemaId", setting.id);
        commit("setEventSettingsSchema", setting.attributes?.meta_data);
        return setting;
      } else {
        commit("setEventSettingsSchemaId", null);
        return null;
      }
    } finally {
      commit("setLoadingEventSettings", false);
    }
  },

  async saveMetaSettings({ commit }, props) {
    const orgId = localStorage.getItem("orgId");
    commit("setLoadingEventSettings", true);

    let data = props.data.setting || {};
    data[props.key || "value"] = props.value;

    if (props.data.settingId) {
      try {
        await updateSetting(
          { cid: store.state.dashboard.selectedCampaign, oid: orgId },
          props.metaName,
          props.data.settingId,
          data
        );
      } finally {
        commit("setLoadingEventSettings", false);
      }
    } else {
      try {
        await createSetting(
          { cid: store.state.dashboard.selectedCampaign, oid: orgId },
          props.metaName,
          data
        );
      } finally {
        commit("setLoadingEventSettings", false);
      }
    }
  },

  async getMetaSettings({ commit }, metaName) {
    const orgId = localStorage.getItem("orgId");
    commit("setLoadingEventSettings", true);
    try {
      const setting = await getSetting(
        {
          cid: store.state.dashboard.selectedCampaign,
          oid: orgId,
          extraParams: {
            falback_to_meta_dir: 1,
          },
        },
        metaName
      );

      if (setting && setting.id != "0") {
        return { setting: setting.attributes.meta_data, settingId: setting.id };
      }

      if (
        setting.id === "0" &&
        Object.keys(setting.attributes?.default_meta_value_off).length > 0
      ) {
        return { setting: setting.attributes?.default_meta_value_off, settingId: null };
      } else {
        return { setting: setting.attributes?.meta_data, settingId: null };
      }
    } finally {
      commit("setLoadingEventSettings", false);
    }
  },

  setPinedItem({ state, commit, dispatch }, props) {
    let currentList = state.eventSettingsSchema?.display?.pin?.manual_list;

    currentList = removeItemFromArray(currentList, props.team_id, props.donation_id);
    if (props.add) {
      currentList.push({ team_id: props.team_id, donation_id: props.donation_id });
    }

    const newForm = {
      ...state.eventSettingsSchema,
      display: {
        ...state.eventSettingsSchema.display,
        pin: {
          ...state.eventSettingsSchema.display.pin,
          manual_list: [...currentList],
        },
      },
    };

    commit("setEventSettingsSchema", newForm);
    return dispatch("saveEventSettings", { form: newForm });
  },

  async changeEventDonationsList({ commit }, props) {
    commit("setLoadingEventDonationsList", true);
    const orgId = localStorage.getItem("orgId");
    return axios
      .put(urls.eventDonationList(orgId, store.state.dashboard.selectedCampaign), {
        ...(props?.params || {}),
      })
      .then((response) => {
        return response;
      })
      .finally(() => {
        commit("setLoadingEventDonationsList", false);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
