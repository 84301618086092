export default ($t) => ({
  title: "AnnouncementPopup",
  type: "object",
  expanded: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    content: {
      title: $t("edit_campaign.schema.announcement_popup.content", "Content"),
      expanded: true,
      type: "string",
      default: "",
      description: $t("edit_campaign.schema.announcement_popup.content_description", {
        returnEmptyOnMissing: true,
      }),
    },
    size: {
      title: $t("edit_campaign.schema.announcement_popup.size", "Size"),
      type: "enum",
      default: "md",
      description: $t("edit_campaign.schema.announcement_popup.size_description", {
        returnEmptyOnMissing: true,
      }),
      items: {
        enum: [
          {
            text: $t("edit_campaign.schema.announcement_popup.items.lg", "Lg"),
            value: "lg",
          },
          {
            text: $t("edit_campaign.schema.announcement_popup.items.lg", "Md"),
            value: "md",
          },
        ],
      },
    },
    delay: {
      title: $t("edit_campaign.schema.announcement_popup.delay", "Delay"),
      type: "number",
      default: 5000,
      description: $t("edit_campaign.schema.announcement_popup.delay_description", {
        returnEmptyOnMissing: true,
      }),
    },
    action_button: {
      title: $t("edit_campaign.schema.announcement_popup.action_button.type", "Action button"),
      type: "object",
      expanded: true,
      properties: {
        type: {
          title: $t("edit_campaign.schema.announcement_popup.action_button.type", "Type"),
          type: "enum",
          default: "donate",
          description: $t(
            "edit_campaign.schema.announcement_popup.action_button.type_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
          items: {
            enum: [
              {
                text: $t(
                  "edit_campaign.schema.announcement_popup.action_button.type.donate",
                  "donate"
                ),
                value: "donate",
              },
              {
                text: $t(
                  "edit_campaign.schema.announcement_popup.action_button.type.add_team",
                  "add_team"
                ),
                value: "add_team",
              },
            ],
          },
        },
        params: {
          title: $t(
            "edit_campaign.schema.announcement_popup.action_button.params.title",
            "Params key-value"
          ),
          type: "key-value",
          default: {},
          keyPlaceholder: $t(
            "edit_campaign.schema.announcement_popup.action_button.params.key",
            "amount"
          ),
          valuePlaceholder: $t(
            "edit_campaign.schema.announcement_popup.action_button.params.value",
            "500"
          ),
          description: $t(
            "edit_campaign.schema.announcement_popup.action_button.params_description",
            {
              returnEmptyOnMissing: true,
            }
          ),
        },
      },
    },
  },
});
