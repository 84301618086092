<template>
  <b-modal ref="modal" size="lg" no-close-on-backdrop hide-footer>
    <div slot="modal-header">
      <h2>
        {{ $t("gateways.more_payment_methods_title", "More payment methods") }}
      </h2>
      <div class="modal-header__hide" @click="modalClose">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </div>
    </div>

    <error v-if="errors" :errors="errors"></error>

    <b-overlay :show="loading" rounded="sm">
      <div class="d-flex flex-wrap">
        <div
          v-for="[code, data] in paymentMethodEntries"
          :key="code"
          class="d-flex align-items-center w-50 py-2 payment-method-item"
        >
          <div>
            <b-button
              :variant="added.includes(code) ? 'success' : 'outline-success'"
              pill
              :disabled="added.includes(code)"
              class="add-btn"
              @click="addNewPaymentMethod(code)"
            >
              {{
                added.includes(code)
                  ? $t("gateways.added_payment_method", "Added")
                  : $t("gateways.add_payment_method", "Add")
              }}
            </b-button>
          </div>
          <div class="mx-1"></div>
          <div class="label">
            {{ $t(`gateways.${code}_payment_method_title`, data.label) }}
          </div>
        </div>
      </div>
    </b-overlay>

    <div class="text-center mt-5">
      <button class="bttn bttn--lg bttn--blue" type="button" name="button" @click="modalClose">
        {{ $t("gateways.close", "Close") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import { stripePaymentMethods, isEU } from "@/constants/stripe-payment-methods";
import axios from "axios";
import urls from "@/constants/urls";
import error from "@/components/errors.vue";

export default {
  components: {
    error,
  },

  data() {
    return {
      stripePaymentMethods,

      added: [],
      cardGtw: null,
      loading: false,

      errors: [],
    };
  },

  computed: {
    orgId: () => localStorage.getItem("orgId"),

    paymentMethodEntries() {
      if (this.cardGtw) {
        return Object.entries(this.stripePaymentMethods).filter(([, data]) => {
          if (data.countries.length === 0) {
            return true;
          }

          const { country } = this.cardGtw.attributes.gateway_data;
          if (data.countries.includes(country)) {
            return true;
          }

          if (data.countries.includes("EU")) {
            return isEU(this.cardGtw.attributes.gateway_data.country);
          }

          return false;
        });
      }
      return [];
    },
  },

  methods: {
    open(gtwList) {
      this.added = [];
      gtwList.forEach((gtw) => {
        const pmt = gtw.attributes.gateway_data.payment_method_types.trim();
        let paymentMethodTypes = pmt.split("|");
        if (pmt === "") {
          paymentMethodTypes = [];
        }
        if (paymentMethodTypes.includes("card") || paymentMethodTypes.length === 0) {
          this.cardGtw = gtw;
        }
        this.added.push(...paymentMethodTypes);
      });
      if (!this.cardGtw && gtwList.length > 0) {
        this.cardGtw = gtwList[0];
      }
      this.$refs.modal.show();
    },

    modalClose() {
      this.$refs.modal.hide();
    },

    showError(e) {
      if (!e.response) return;

      if (e.response?.data?.error) {
        this.errors.push(e.response.data.error);
      } else if (e.response?.data?.errors) {
        this.errors = e.response.data.errors.map((err) => err.detail);
      } else {
        this.errors.push(this.$t("template.error_default"));
      }
    },

    addNewPaymentMethod(option) {
      this.errors = [];
      this.loading = true;

      const params = { option };

      axios
        .post(
          urls.getewayFromStripe
            .replace(":orgId", this.orgId)
            .replace(":merchantInfoId", this.cardGtw.id)
            .replace(":gtw", this.cardGtw.attributes.gateway),
          null,
          { params }
        )
        .then(() => {
          this.loading = false;

          this.$store.dispatch("getGateways");

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("gateways.added_successfully", "Added successfully"),
          });

          this.added.push(option);
        })
        .catch((e) => {
          this.loading = false;
          this.showError(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.modal-header {
  position: relative;
  h2 {
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: inline-block;
      cursor: pointer;
      margin-right: 10px;
      position: absolute;
      top: 0;
      left: -30px;
      svg {
        fill: gray;
      }
    }
  }
}

.add-btn {
  min-width: 80px;
}

.payment-method-item {
  max-width: 33%;

  .label {
    font-family: $vito-bold;
  }
}
</style>
