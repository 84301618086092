<template>
  <b-modal
    id="event-page-edit-modal"
    size="lg"
    centered
    hide-backdrop
    dialog-class="!tw-max-w-[764px]"
    :content-class="deleteMode ? 'delete-mode' : ''"
  >
    <template slot="modal-header">
      <div class="grid-title">
        <span>Name</span>
        <span>Amount</span>
        <span>Type of Donation</span>
        <span>Team</span>
      </div>
      <button type="button" aria-label="Close" class="close" @click="hideModal">×</button>
    </template>
    <div class="tw-relative">
      <div class="content">
        <div class="grid-content">
          <p class="tw-mb-0" v-if="anonymous || this.name === 'Anonymous'">Anonymous</p>
          <event-page-input
            v-else
            type="text"
            v-model="name"
            class="tw-h-[62px] !tw-p-0 tw-ms-[-32px]"
            fontSize="20"
          />
          <p v-if="!isTeam" class="tw-mb-0">{{ amount }}</p>
          <event-page-input
            v-else
            type="number"
            v-model="amount"
            class="tw-h-[62px] !tw-p-0 tw-ms-[-32px] !tw-w-[calc(100%+32px)]"
            fontSize="20"
          >
            <template #icon>
              {{ currencySign }}
            </template>
          </event-page-input>
          <p class="tw-mb-0 tw-truncate">{{ typeOfDonation }}</p>
          <p class="tw-mb-0 tw-truncate">{{ item.team_name || "-" }}</p>
        </div>
        <b-form-checkbox size="lg" class="event-checkbox" v-model="anonymous" v-if="!isTeam">
          Donated Anonymously
        </b-form-checkbox>
      </div>
    </div>
    <template slot="modal-footer">
      <button v-if="!deleteMode && !isTeam" class="delete-btn" @click="deleteMode = true">
        {{ this.$t("eventpage.donations.modal.delete", "Delete Donation") }}
      </button>
      <btn v-if="!deleteMode" class="modal-btn" type="secondary" @click="hideModal">
        {{ this.$t("eventpage.donations.modal.cancel", "Cancel") }}
      </btn>
      <btn v-else class="modal-btn" type="secondary" @click="deleteMode = false">
        {{ this.$t("eventpage.donations.modal.cancel", "Cancel") }}
      </btn>
      <btn v-if="!deleteMode" class="modal-btn" type="primary" :loading="loading" @click="saveItem">
        {{ this.$t("eventpage.donations.modal.save", "Save Changes") }}
      </btn>
      <btn v-else class="modal-btn" type="danger" :loading="loading" @click="deleteItem">
        {{ this.$t("eventpage.donations.modal.delete.confirm", "Delete") }}
      </btn>
    </template>
  </b-modal>
</template>

<script>
import Btn from "@/views/event-page/ui/btn.vue";
import axios from "axios";
import urls from "@/constants/urls";
import EventPageInput from "@/views/event-page/ui/input.vue";

export default {
  name: "event-page-edit-modal",

  components: {
    Btn,
    EventPageInput,
  },

  data() {
    return {
      loading: false,
      deleteMode: false,
      name: "",
      amount: "",
      anonymous: false,
    };
  },

  props: {
    item: {
      type: Object,
      default: () => null,
    },
    currencySign: {
      type: String,
      default: "$",
    },
  },

  computed: {
    campaignId() {
      return this.$store.state.dashboard.selectedCampaign;
    },
    orgId() {
      return localStorage.getItem("orgId");
    },
    typeOfDonation() {
      if (this.item.donor_name && this.item.team_name) {
        return "Donor+Ambassador";
      } else if (this.item.donor_name) {
        return "Donor";
      } else {
        return "Ambassador";
      }
    },
    isTeam() {
      return this.typeOfDonation === "Ambassador";
    },
  },

  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "event-page-edit-modal");
    },

    openModal() {
      this.$root.$emit("bv::show::modal", "event-page-edit-modal");

      if (this.item) {
        this.anonymous = this.item.donor_name === "Anonymous";
        this.deleteMode = false;
        this.name = this.item.donor_name || this.item.team_name;
        this.amount = this.isTeam ? this.item.team_goal : this.item.donation_amount;
      }
    },

    async setOptions(obj) {
      this.loading = true;
      const url = urls.eventDonationList(this.orgId, this.campaignId);

      try {
        await axios.put(url, obj, {
          params: {
            ...(this.item.team_id && { team_id: this.item.team_id }),
            ...(this.item.donation_id && { donation_id: this.item.donation_id }),
          },
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loading = false;
        this.hideModal();
        this.$emit("update");
      }
    },

    async deleteItem() {
      this.loading = true;
      const url = urls.eventDonationList(this.orgId, this.campaignId);

      try {
        await axios.delete(url, {
          params: {
            ...(this.item.team_id && { team_id: this.item.team_id }),
            ...(this.item.donation_id && { donation_id: this.item.donation_id }),
          },
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loading = false;
        this.hideModal();
        this.deleteMode = false;
        this.$emit("update");
      }
    },

    saveItem() {
      this.setOptions({
        anonymous: this.anonymous,
        ...(this.anonymous === false && this.name !== "Anonymous" ? { name: this.name } : {}),
        ...(this.isTeam && { goal: this.validateAmount(this.amount) }),
      });
    },

    validateAmount(amount) {
      if (amount === "") {
        return 0;
      }

      const parsedAmount = parseInt(amount, 10);

      if (Number.isInteger(parsedAmount) && parsedAmount >= 0) {
        return parsedAmount;
      }

      return this.item.donation_amount;
    },
  },
};
</script>

<style lang="scss">
#event-page-edit-modal {
  --grid: 5fr 3fr 4fr 2fr;
  --gap: 26px;
  --pi-start: 64px;
  --pi-end: 36px;
  --pf: 30px;
  --b-color: transparent;
  font-family: var(--font-secondary);

  @media (max-width: 560px) {
    --grid: 1fr 1fr;
    --gap: 18px;
    --pi-start: 24px;
    --pi-end: 24px;
    --pf: 24px;
  }

  .modal-dialog {
    max-width: 688px;
  }

  .delete-mode {
    .content {
      pointer-events: none;
      opacity: 0.6;
    }
  }

  .modal-content {
    border-radius: 20px;
    font-size: 20px;
    overflow: hidden;
    box-shadow: 0px 2.3px 40px rgba(0, 0, 0, 0.05);

    .modal-header {
      position: relative;
      padding: 0;
      padding-block: 20px;
      padding-inline-start: var(--pi-start);
      padding-inline-end: var(--pi-end);
      border: 3px solid var(--b-color);
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      border-bottom: none;
      background: var(--event-primary);
      color: var(--event-white);
      @media (max-width: 560px) {
        height: 73px;
      }

      .grid-title {
        display: grid;
        grid-template-columns: var(--grid);
        gap: var(--gap);
        width: 100%;
        @media (max-width: 560px) {
          opacity: 0;
        }
      }

      .close {
        position: absolute;
        right: 18px;
        color: var(--event-not-active);
        text-shadow: none;
      }
    }
    .modal-footer {
      gap: 10px;
      align-items: flex-end;
      padding: 0;
      padding-inline-start: var(--pi-start);
      padding-inline-end: var(--pi-end);
      padding-bottom: var(--pf);
      border: 3px solid var(--b-color);
      border-top: none;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    .modal-body {
      padding: 0;
      padding-inline-start: var(--pi-start);
      padding-inline-end: var(--pi-end);
      border: 3px solid var(--b-color);
      border-top: none;
      border-bottom: none;
    }

    .grid-content {
      display: grid;
      grid-template-columns: var(--grid);
      gap: var(--gap);
      align-items: center;
      margin-block-start: 14px;
      margin-block-end: 30px;
      min-height: 62px;
    }
  }

  .event-checkbox {
    .custom-control-label {
      padding-block-start: 2px;
      font-size: 17px;
      cursor: pointer;
    }
  }

  .modal-btn {
    height: 44px;
    font-size: 17px;
  }

  .delete-btn {
    margin: 0;
    margin-right: auto;
    padding: 0;
    padding-bottom: 6px;
    border: none;
    text-decoration: underline;
    background: none;
    font-size: 17px;
    color: var(--event-danger);
  }
}
</style>
