<template>
  <div class="d-list-item">
    <button class="edit-btn" @click="$emit('edit')">
      <icon-pin-card />
    </button>
    <div class="d-list-item__name">
      <icon-anonymous v-if="item.donor_name === 'Anonymous'" />
      <span>{{ item.donor_name || item.team_name }}</span>
    </div>
    <div class="tw-relative">
      <div class="online-status" :class="{ online: item.bank_name !== 'check' }" />
      {{ currencySign }}{{ (Number(item.donation_amount) || 0) + (Number(item.team_goal) || 0) }}
    </div>
    <div>{{ typeOfDonation }}</div>
    <div class="tw-flex">
      <checkbox-switch
        v-model="published"
        altcolor
        @input="publishedHandler"
        :disabled="isLoading"
      />
    </div>
    <div class="tw-flex">
      <checkbox-switch v-model="pin" @input="pinHandler" :disabled="isLoading" />
    </div>
  </div>
</template>

<script>
import CheckboxSwitch from "@/views/event-page/ui/checkbox-switch.vue";
import iconPinCard from "@/views/event-page/icons/pin-card.vue";
import iconAnonymous from "@/views/event-page/icons/anonymous.vue";
import axios from "axios";
import urls from "@/constants/urls";
import { mapState } from "vuex";

export default {
  name: "event-page-settings",
  data() {
    return {
      loading: false,
      pin: false,
      published: false,
      ignoreInput: true,
    };
  },

  components: {
    CheckboxSwitch,
    iconPinCard,
    iconAnonymous,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    currencySign: {
      type: String,
      default: "$",
    },
  },

  watch: {
    eventSettingsList: {
      immediate: true,
      handler(newList) {
        this.ignoreInput = true;

        const index = this.searchElement(newList?.display?.pin?.manual_list, this.item);
        this.pin = index !== -1;

        this.$nextTick(() => {
          this.ignoreInput = false;
        });
      },
    },
    item: {
      immediate: true,
      handler(newItem) {
        this.ignoreInput = true;

        const index = this.searchElement(
          this.eventSettingsList?.display?.pin?.manual_list,
          newItem
        );
        this.pin = index !== -1;
        this.published = newItem.published;

        this.$nextTick(() => {
          this.ignoreInput = false;
        });
      },
    },
  },

  computed: {
    ...mapState({
      eventSettingsList: (state) => {
        return state.eventPlatform.eventSettingsSchema;
      },
      isLoading: (state) => {
        return (
          state.eventPlatform.loadingEventDonationsList || state.eventPlatform.loadingEventSettings
        );
      },
    }),

    campaignId() {
      return this.$store.state.dashboard.selectedCampaign;
    },
    orgId() {
      return localStorage.getItem("orgId");
    },
    typeOfDonation() {
      if (this.item.donor_name && this.item.team_name) {
        return "Donor+Ambassador";
      } else if (this.item.donor_name) {
        return "Donor";
      } else {
        return "Ambassador";
      }
    },
    isDonor() {
      return this.typeOfDonation === "Donor";
    },
  },
  methods: {
    searchElement(list, item) {
      return list?.findIndex(
        (searchedItem) =>
          item.team_id === searchedItem.team_id && item.donation_id === searchedItem.donation_id
      );
    },

    async setOptions(obj) {
      this.loading = true;
      const url = urls.eventDonationList(this.orgId, this.campaignId);

      try {
        await axios.put(url, obj, {
          params: {
            ...(this.item.team_id && { team_id: this.item.team_id }),
            ...(this.item.donation_id && { donation_id: this.item.donation_id }),
          },
        });
        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.setting_saved", "Saved successfully"),
          "success"
        );
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      } finally {
        this.loading = false;
        this.$emit("update");
      }
    },

    publishedHandler(e) {
      if (this.ignoreInput) return;
      this.setOptions({ publish: e });
    },

    pinHandler(e) {
      if (this.ignoreInput) return;

      this.$store
        .dispatch("setPinedItem", {
          team_id: this.item.team_id,
          donation_id: this.item.donation_id,
          add: e,
        })
        .then(() => {
          this.$_notificationsMixin_makeToast(
            "Success",
            this.$t("edit_campaign.setting_saved", "Saved successfully"),
            "success"
          );
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
          throw e;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.d-list-item {
  display: var(--dlist-display);
  grid-template-columns: var(--dlist-columns);
  column-gap: var(--dlist-column-gap);
  align-items: center;
  padding-block: 15px;
  border: 1px solid var(--event-border);
  border-top: none;

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &__name {
    position: relative;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      position: absolute;
      left: -16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .online-status {
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 9999px;
    background-color: var(--event-danger);

    &.online {
      background-color: var(--event-success);
    }
  }
}

.edit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-inline-start: 24px;
  border: none;
  border-radius: 9999px;
  background-color: var(--event-secondary);
}
</style>
