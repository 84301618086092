<template>
  <div
    class="event-stats"
    v-if="Object.keys(campaign).length > 0 && Object.keys(dashboardCampaign).length > 0"
  >
    <div class="es-card es-card--mark">
      <div class="es-card-item">
        <div class="es-card-item__left">
          <p class="es-card-item__text text-l">Campaign Goal</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ campaignGoal }}</p>
        </div>
      </div>
      <div class="es-card-item">
        <div class="es-card-item__left">
          <p class="es-card-item__text text-m">Total Raised</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaisedAll }}</p>
        </div>
        <Badge :qty="totalDonations" />
      </div>
      <div class="es-card-item__row">
        <div class="es-card-item">
          <p class="es-card-item__text">Published</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaisedAllPublished }}</p>
        </div>
        <div class="es-card-item">
          <p class="es-card-item__text">Pending</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaisedAllPending }}</p>
        </div>
      </div>
      <div class="es-card-item">
        <b-progress max="100" class="es-card__progress">
          <b-progress-bar
            :value="percentage"
            :label-html="`<span>${percentage}%</span>`"
          ></b-progress-bar>
        </b-progress>
      </div>
    </div>

    <div class="es-card">
      <div class="es-card-item">
        <div class="es-card-item__left">
          <p class="es-card-item__text text-l">Total Donors</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaised }}</p>
        </div>
        <Badge :qty="totalDonations" />
      </div>
      <div class="es-card-item es-card-item__online">
        <span class="es-card-item__online__text">
          Online: {{ currency_sign }}{{ onlineDonations }}
        </span>
        <span class="es-card-item__online__text">
          Offline: {{ currency_sign }}{{ offlineDonations }}
        </span>
      </div>
      <div class="es-card-item">
        <div class="es-card-item__left">
          <p class="es-card-item__text">Published</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaisedPublished }}</p>
        </div>
        <Badge :qty="totalRaisedPublishedCount" />
      </div>
      <div class="es-card-item">
        <div class="es-card-item__left">
          <p class="es-card-item__text">Pending</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaisedPending }}</p>
        </div>
        <Badge :qty="totalRaisedPendingCount" />
      </div>
    </div>

    <div class="es-card">
      <div class="es-card-item">
        <div class="es-card-item__left">
          <p class="es-card-item__text text-l">Total Teams</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaisedTeams }}</p>
        </div>
        <Badge :qty="totalTeams" />
      </div>
      <div class="es-card-item mt-auto">
        <div class="es-card-item__left">
          <p class="es-card-item__text">Published</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaisedTeamsPublished }}</p>
        </div>
        <Badge :qty="totalRaisedTeamsPublishedCount" />
      </div>
      <div class="es-card-item">
        <div class="es-card-item__left">
          <p class="es-card-item__text">Pending</p>
          <p class="es-card-item__value">{{ currency_sign }}{{ totalRaisedTeamsPending }}</p>
        </div>
        <Badge :qty="totalRaisedTeamsPendingCount" />
      </div>
    </div>

    <div class="es-card es-card--wide">
      <p class="es-card-item__text text-l">Total people at Event</p>
      <p class="es-card-item__value">XXX</p>
    </div>

    <div class="es-card-btns">
      <button class="es-btn" @click="activateConfetti">
        <icon icon-name="confetti" width="26" height="26" />
      </button>
      <button class="es-btn" @click="updateData"><iconRefresh /></button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Badge from "@/views/event-page/event-stats/badge.vue";
import icon from "@/views/event-page/icons";
import iconRefresh from "@/views/event-page/icons/refresh.vue";
import { notificationsMixin } from "@/mixins";

export default {
  name: "event-stats",

  components: {
    Badge,
    icon,
    iconRefresh,
  },

  mixins: [notificationsMixin],

  data() {
    return {
      loading: false,
      display: {
        confetti: {
          activation_timestamp: null,
        },
      },
    };
  },

  computed: {
    ...mapState({
      campaign: (state) => state.campaign?.campaign?.data?.attributes || {},
      dashboardCampaign: (state) => state.dashboard?.campaignDashboard?.data?.attributes || {},
      campaignId: (state) => state.dashboard.selectedCampaign,
      eventStats: (state) => state.eventPlatform.eventStats || {},
    }),
    currency_sign() {
      return this.campaign?.currency_sign || "$";
    },
    campaignGoal() {
      return this.campaign?.rounds?.[0]?.goal?.toLocaleString() || "-";
    },

    totalRaisedAll() {
      const total =
        Number(this.dashboardCampaign.total_raised) +
        Number(this.eventStats.pending_donations_total_amount) +
        Number(this.eventStats.pending_teams_total_amount) +
        Number(this.eventStats.published_teams_total_goal);

      return isNaN(total) ? " -" : total.toLocaleString();
    },
    totalRaisedAllPending() {
      const total =
        Number(this.eventStats.pending_donations_total_amount) +
        Number(this.eventStats.pending_teams_total_amount);

      return isNaN(total) ? " -" : total.toLocaleString();
    },
    totalRaisedAllPublished() {
      const total =
        Number(this.dashboardCampaign.total_raised) +
        Number(this.eventStats.published_teams_total_goal);

      return isNaN(total) ? " -" : total.toLocaleString();
    },
    totalRaised() {
      const total =
        Number(this.dashboardCampaign.total_raised) +
        Number(this.eventStats.pending_donations_total_amount);

      return isNaN(total) ? " -" : total.toLocaleString();
    },
    totalRaisedPublished() {
      return this.dashboardCampaign.total_raised?.toLocaleString() || "-";
    },
    totalRaisedPublishedCount() {
      return this.eventStats.published_total_donations_count?.toLocaleString() || "-";
    },
    totalRaisedPending() {
      return this.eventStats.pending_donations_total_amount?.toLocaleString() || "-";
    },
    totalRaisedPendingCount() {
      return this.eventStats.pending_total_donations_count?.toLocaleString() || "-";
    },
    totalRaisedTeams() {
      const total =
        Number(this.eventStats.pending_teams_total_amount) +
        Number(this.eventStats.published_teams_total_goal);

      return isNaN(total) ? " -" : total.toLocaleString();
    },
    totalRaisedTeamsPending() {
      return this.eventStats.pending_teams_total_amount?.toLocaleString() || "-";
    },
    totalRaisedTeamsPendingCount() {
      return this.eventStats.pending_total_teams_count?.toLocaleString() || "-";
    },
    totalRaisedTeamsPublished() {
      return this.eventStats.published_teams_total_goal?.toLocaleString() || "-";
    },
    totalRaisedTeamsPublishedCount() {
      return this.eventStats.published_total_teams_count?.toLocaleString() || "-";
    },
    totalDonations() {
      return this.dashboardCampaign?.total_donations?.toString() || "-";
    },
    totalTeams() {
      return this.eventStats.total_teams_count?.toString() || "-";
    },
    onlineDonations() {
      const total =
        Number(this.dashboardCampaign?.total_raised) -
        Number(this.dashboardCampaign?.total_raised_checks);

      return isNaN(total) ? " -" : total.toLocaleString();
    },
    offlineDonations() {
      return this.dashboardCampaign?.total_raised_checks?.toLocaleString() || "-";
    },
    percentage() {
      const total = this.campaign?.rounds?.[0]?.goal;

      const collected =
        Number(this.dashboardCampaign.total_raised) +
          Number(this.eventStats.pending_donations_total_amount) +
          Number(this.eventStats.pending_teams_total_amount) +
          Number(this.eventStats.published_teams_total_goal) || 0;

      const percent = Math.round((collected / total) * 100);
      return percent > 100 ? 100 : percent < 0 ? 0 : percent;
    },
  },
  methods: {
    activateConfetti() {
      this.display.confetti.activation_timestamp = new Date().toISOString();
      console.log("Confetti activated at:", this.display.confetti.activation_timestamp);

      this.saveConfettiTimestamp();
    },

    async saveConfettiTimestamp() {
      try {
        // Get current settings
        const currentSettings = this.$store.state.eventPlatform?.eventSettingsSchema || {};

        // Update with new timestamp
        const updatedSettings = {
          ...currentSettings,
          display: {
            ...currentSettings.display,
            confetti: {
              ...currentSettings.display?.confetti,
              activation_timestamp: this.display.confetti.activation_timestamp,
            },
          },
        };

        // Save updated settings
        await this.$store.dispatch("saveEventSettings", { form: updatedSettings });

        // Show success notification
        this.$_notificationsMixin_makeToast(
          "Success",
          "Confetti activated successfully",
          "success"
        );
      } catch (e) {
        console.error("Error saving confetti timestamp:", e);
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },

    updateData() {
      this.$emit("update");
    },
  },
};
</script>

<style lang="scss" scoped>
.event-stats {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  .es-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
    padding-block: 26px;
    padding-inline-start: 35px;
    padding-inline-end: 30px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.55);

    &-item__row {
      display: flex;
      justify-content: space-between;

      .es-card-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    &-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
      color: var(--event-soft-dark);

      .mt-auto {
        margin-block-start: auto;
      }

      &__left {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      &__text {
        margin: 0;
        font-size: 15px;
        line-height: 1;
        font-weight: 700;
      }

      .text-l {
        margin: 0;
        font-size: 15px;
        font-weight: 900;
      }

      .text-m {
        margin: 0;
        font-size: 15px;
        font-weight: 700;
      }

      &__value {
        margin: 0;
        color: var(--event-primary);
        font-size: 20px;
        line-height: 1;
        font-weight: 700;
      }

      &__online {
        display: flex;
        flex-direction: row;
        gap: 14px;

        &__text {
          position: relative;
          display: inline-block;
          padding-left: 17px;
          color: var(--event-soft-dark);
          font-size: 13px;

          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 2px;
            left: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: var(--event-success);
          }

          &:last-child {
            &::before {
              background: var(--event-danger);
            }
          }
        }
      }
    }

    &--mark {
      border: 2px solid var(--event-primary);
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }

    &__progress {
      width: 100%;
      height: 15px;
      border-radius: 9999px;
      background-color: var(--event-white);

      ::v-deep(.progress-bar) {
        min-width: 26px;
        border-radius: 9999px;
        background-color: var(--event-primary);
        span {
          font-size: 10.6px;
          font-weight: 700;
        }
      }
    }

    &--wide {
      flex-direction: row;
      align-items: center;
      grid-column: 1 / -1;
    }
  }

  .es-card-btns {
    position: absolute;
    top: -112px;
    right: 0;
    display: flex;
    gap: 12px;
  }

  .es-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    border: 1px solid transparent;
    border-radius: 9999px;
    background-color: var(--event-danger);
    color: var(--event-white);
  }

  @media (max-width: 1419px) and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    .es-card--wide {
      grid-column: span 1;
    }
  }

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    .es-card--wide {
      grid-column: span 1;
    }
  }

  @media (max-width: 768px) {
    gap: 16px;
  }

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
    .es-card-btns {
      top: -48px;
    }
  }
}
</style>
