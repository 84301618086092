<template>
  <div class="input">
    <label class="input__label" :for="id" v-if="label">
      {{ label }}
    </label>
    <div class="input__container" :style="{ width: size ? `${size}px` : '100%' }">
      <span class="input__icon">
        <slot name="icon" />
      </span>
      <span v-if="prefix" class="input__prefix">{{ prefix }}</span>
      <input
        :id="id"
        :type="type"
        :placeholder="placeholder || (type === 'number' ? 0 : '')"
        v-model="localValue"
        :style="{ fontSize: fontSize ? `${fontSize}px` : '' }"
      />
      <span v-if="sufix" class="input__sufix">{{ sufix }}</span>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  name: "input-text-number",

  data() {
    return {
      id: "",
      loading: false,
    };
  },

  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    sufix: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", this.type === "text" ? newValue : Number(newValue));
      },
    },
  },

  mounted() {
    this.id = nanoid();
  },
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  padding-left: var(--padding-left);
  padding-block: 36px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding-left: 0;
  }

  &__label {
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    flex-shrink: 0;
    background: var(--event-ui-bg);
    border-radius: 9999px;
    padding: 16px 32px;
    display: flex;
    align-items: center;
    gap: 11px;

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  &__prefix {
    color: var(--event-primary);
    font-size: 27px;
    font-weight: 700;
    line-height: 1;
  }

  &__sufix {
    color: var(--event-primary);
    font-size: 27px;
    font-weight: 700;
    line-height: 1;
  }

  &__icon {
    flex-shrink: 0;
    max-height: 29px;
    &:empty {
      display: none;
    }
  }

  input {
    width: 100%;
    border: none;
    background: transparent;
    font-size: 23px;
    line-height: 1;
    color: var(--event-soft-dark);
    width: 100%;
    outline: none;
    padding: 0;
    &::placeholder {
      color: var(--event-soft-dark);
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}
</style>
