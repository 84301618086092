export const stripePaymentMethods = {
  card: { label: "Cards", countries: [], currencies: [] },
  us_bank_account: { label: "ACH Direct Debit", countries: ["US"], currencies: ["USD"] },
  bacs_debit: { label: "Bacs Direct Debit", countries: ["UK"], currencies: ["GBP"] },
  au_becs_debit: { label: "BECS direct debit", countries: ["AU"], currencies: ["AUD"] },
  acss_debit: {
    label: "Pre-authorized debit in Canada",
    countries: ["CA", "US"],
    currencies: ["CAD", "USD"],
  },
  sepa_debit: {
    label: "SEPA debit",
    countries: ["AU", "CA", "EU", "HK", "JP", "MX", "NZ", "SG", "US"],
    currencies: ["EUR"],
  },
  bancontact: {
    label: "Bancontact",
    countries: ["AU", "CA", "EU", "HK", "JP", "MX", "NZ", "SG", "US"],
    currencies: ["EUR"],
  },
  customer_balance: {
    label: "Bank transfers",
    countries: ["EU", "JP", "MX", "UK", "US"],
    currencies: ["EUR", "GBP", "JPY", "MXN", "USD"],
  },
  blik: {
    label: "BLIK",
    countries: [
      "AT",
      "BE",
      "BG",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GB",
      "GR",
      "HR",
      "HU",
      "IE",
      "IS",
      "IT",
      "LI",
      "LT",
      "LU",
      "LV",
      "MT",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "SE",
      "SI",
      "SK",
      "US",
    ],
    currencies: ["PLN"],
  },
  eps: {
    label: "EPS",
    countries: ["AU", "CA", "EU", "HK", "JP", "MX", "NZ", "SG", "US"],
    currencies: ["EUR"],
  },
  fpx: { label: "FPX", countries: ["MY"], currencies: ["MYR"] },
  giropay: {
    label: "Giropay",
    countries: ["AU", "CA", "EU", "HK", "JP", "MX", "NZ", "SG", "US"],
    currencies: ["EUR"],
  },
  ideal: {
    label: "iDEAL",
    countries: ["AU", "CA", "EU", "HK", "JP", "MX", "NZ", "SG", "US"],
    currencies: ["EUR"],
  },
  p24: {
    label: "P24",
    countries: ["AU", "CA", "EU", "HK", "JP", "MX", "NZ", "SG", "US"],
    currencies: ["EUR", "PLN"],
  },
  paypal: {
    label: "PayPal",
    countries: [
      "AT",
      "BE",
      "BG",
      "CH",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GB",
      "GR",
      "HR",
      "IE",
      "IT",
      "LI",
      "LT",
      "LU",
      "LV",
      "MT",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "SE",
      "SI",
      "SK",
    ],
    currencies: [
      "AUD",
      "CAD",
      "CHF",
      "CZK",
      "DKK",
      "EUR",
      "GBP",
      "HKD",
      "NOK",
      "NZD",
      "PLN",
      "SEK",
      "SGD",
      "USD",
    ],
  },
  affirm: { label: "Affirm", countries: ["CA", "US"], currencies: ["CAD", "USD"] },
  afterpay_clearpay: {
    label: "Afterpay (Clearpay)",
    countries: ["AU", "CA", "ES", "FR", "IE", "NZ", "UK", "US"],
    currencies: ["AUD", "CAD", "NZD", "GBP", "USD", "EUR"],
  },
  klarna: {
    label: "Klarna",
    countries: [
      "AT",
      "AU",
      "BE",
      "CA",
      "CH",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GR",
      "IE",
      "IT",
      "LT",
      "LV",
      "NL",
      "NO",
      "NZ",
      "PL",
      "PT",
      "SE",
      "SI",
      "SK",
      "UK",
      "US",
    ],
    currencies: [
      "AUD",
      "CAD",
      "CHF",
      "CZK",
      "DKK",
      "EUR",
      "GBP",
      "NOK",
      "NZD",
      "PLN",
      "SEK",
      "USD",
    ],
  },
  link: {
    label: "Link",
    countries: [
      "AE",
      "AT",
      "AU",
      "BE",
      "BG",
      "CA",
      "CH",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GB",
      "GI",
      "GR",
      "HK",
      "HR",
      "HU",
      "IE",
      "IT",
      "JP",
      "LI",
      "LT",
      "LU",
      "LV",
      "MT",
      "MX",
      "MY",
      "NL",
      "NO",
      "NZ",
      "PL",
      "PT",
      "RO",
      "SE",
      "SG",
      "SI",
      "SK",
      "US",
    ],
    currencies: [],
  },
  paynow: { label: "PayNow", countries: ["SG"], currencies: ["SGD"] },
  promptpay: { label: "PromptPay", countries: ["TH"], currencies: ["THB"] },
  swish: {
    label: "Swish",
    countries: [
      "AT",
      "BE",
      "BG",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GR",
      "HR",
      "IE",
      "IS",
      "IT",
      "LI",
      "LT",
      "LU",
      "LV",
      "NL",
      "NO",
      "PL",
      "RO",
      "SE",
      "SI",
      "SK",
    ],
    currencies: ["SEK"],
  },
  boleto: { label: "Boleto", countries: ["BR"], currencies: ["BRL"] },
  konbini: { label: "Konbini", countries: ["JP"], currencies: ["JPY"] },
  oxxo: { label: "OXXO", countries: ["MX"], currencies: ["MXN"] },
  alipay: {
    label: "Alipay",
    countries: ["AU", "CA", "EU", "HK", "JP", "NZ", "SG", "US"],
    currencies: ["AUD", "CAD", "CNY", "EUR", "GBP", "HKD", "JPY", "MYR", "NZD", "SGD", "USD"],
  },
  cashapp: { label: "Cash App Pay", countries: ["US"], currencies: ["USD"] },
  grabpay: { label: "GrabPay", countries: ["MY", "SG"], currencies: ["MYR", "SGD"] },
  mobilepay: {
    label: "MobilePay",
    countries: [
      "AT",
      "BE",
      "BG",
      "CY",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FR",
      "GR",
      "HR",
      "HU",
      "IE",
      "IS",
      "IT",
      "LI",
      "LT",
      "LU",
      "LV",
      "MT",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "SE",
      "SI",
      "SK",
    ],
    currencies: ["DKK", "EUR", "NOK", "SEK"],
  },
  wechat_pay: {
    label: "WeChat Pay",
    countries: ["AU", "CA", "EU", "HK", "JP", "SG", "UK", "US"],
    currencies: [
      "CNY",
      "AUD",
      "CAD",
      "EUR",
      "GBP",
      "HKD",
      "JPY",
      "SGD",
      "USD",
      "DKK",
      "NOK",
      "SEK",
      "CHF",
    ],
  },
  zip: { label: "Zip", countries: ["AU"], currencies: ["AUD"] },
};

/**
 * Checks if a given country code is within the European Union.
 *
 * @param {string} countryCode - The two-letter country code (ISO 3166-1 alpha-2).
 * @returns {boolean} - Returns true if the country is in the EU, false otherwise.
 */
export function isEU(countryCode) {
  const euCountries = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FR",
    "GR",
    "HR",
    "HU",
    "IE",
    "IT",
    "LT",
    "LU",
    "LV",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SE",
    "SI",
    "SK",
  ];

  if (typeof countryCode !== "string" || countryCode.length !== 2) {
    return false; // Invalid input
  }

  return euCountries.includes(countryCode.toUpperCase()); // Case-insensitive check
}
