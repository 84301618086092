<template>
  <div v-if="campaignId">
    <event-header-hero class="event-donations-hero" title="Event Stats">
      <event-stats @update="update({ showToast: true })" />
    </event-header-hero>

    <div class="event-donations tw-pb-[82px] tw-mt-[50px]">
      <div class="event-donations__actions">
        <event-page-input
          type="text"
          placeholder="Search"
          v-model="search"
          class="event-donations__search"
        >
          <template #icon>
            <icon-search />
          </template>
        </event-page-input>
        <FilterAndSort @filters="setFilters" />
        <btn type="primary" class="tw-ms-auto tw-shrink-0" @click="publishAll">
          {{ this.$t("eventpage.settings.publishAll", "Publish All") }}
        </btn>
      </div>
      <div class="d-list" v-if="eventList.length">
        <div class="d-list-header">
          <div></div>
          <div class="d-list-header__name">Name</div>
          <div>Amount</div>
          <div>Type of Donation</div>
          <div>Publish</div>
          <div>Pin</div>
        </div>
        <d-item
          v-for="(item, ind) in eventList"
          :key="ind"
          :item="item"
          :currencySign="currencySign"
          @edit="edit(item)"
          @update="update"
        />
      </div>
    </div>
    <edit-delete-item-modal
      ref="editItemModal"
      :item="currentItem"
      :currencySign="currencySign"
      @update="update"
    />
  </div>
</template>

<script>
import EventPageInput from "@/views/event-page/ui/input.vue";
import iconSearch from "@/views/event-page/icons/search.vue";
import EventHeaderHero from "@/views/event-page/header/header-hero.vue";
import DItem from "@/views/event-page/event-page-donations/event-page-donations-item.vue";
import EditDeleteItemModal from "@/views/event-page/ui/edit-delete-item-modal.vue";
import FilterAndSort from "@/views/event-page/ui/filter-and-sort.vue";
import EventStats from "@/views/event-page/event-stats";
import { mapState } from "vuex";
import { notificationsMixin } from "@/mixins";
import debounce from "lodash/debounce";
import Btn from "@/views/event-page/ui/btn.vue";

export default {
  name: "event-page-donations",

  mixins: [notificationsMixin],

  data() {
    return {
      loading: false,
      data: [],
      search: "",
      currentItem: {},
      filters: {
        sort: "",
        filters: [],
      },
    };
  },

  components: {
    iconSearch,
    EventPageInput,
    EventHeaderHero,
    DItem,
    EditDeleteItemModal,
    FilterAndSort,
    EventStats,
    Btn,
  },

  computed: {
    ...mapState({
      campaign: (state) => state.campaign?.campaign?.data?.attributes || {},
      eventList: (state) => {
        return state.eventPlatform.eventDonations;
      },
    }),

    currencySign() {
      return this.campaign?.currency_sign || "$";
    },

    campaignId() {
      return this.$store.state.dashboard.selectedCampaign;
    },
  },

  methods: {
    setFilters(values) {
      this.filters = { ...values };
    },

    openModal() {
      this.$refs.editItemModal.openModal();
    },

    update(data = null) {
      this.$nextTick(() => {
        this.getData(data);
      });
    },

    edit(item) {
      this.currentItem = { ...item };
      this.$nextTick(() => {
        this.$refs.editItemModal.openModal();
      });
    },

    getData(data) {
      const params = {
        q: this.search || undefined,
      };

      this.filters?.filters?.forEach((filter) => {
        if (filter?.state) {
          params[filter?.value] = 1;
        }
      });

      this.$store.dispatch("getEventDonationsList", { params }).then(() => {
        data?.showToast &&
          this.$_notificationsMixin_makeToast(
            "Success",
            "Update donations successfully",
            "success"
          );
      });
    },

    publishAll() {
      const params = {
        publish_all: true,
      };

      this.$store.dispatch("changeEventDonationsList", { params }).then((data) => {
        data &&
          this.$_notificationsMixin_makeToast(
            "Success",
            "Update donations successfully",
            "success"
          );
        this.getData();
      });
    },
  },

  watch: {
    campaignId: function () {
      this.getData();
    },
    search: debounce(function () {
      this.getData();
    }, 750),
    filters: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.event-donations {
  font-size: 20px;
  color: var(--event-black);

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 25px;
    padding-block-start: 60px;
    padding-block-end: 54px;
  }

  &__search {
    flex-grow: 1;
    width: auto;
    min-width: 240px;
    padding-block: 0;
  }
}

.d-list {
  --dlist-display: grid;
  --dlist-columns: 52px 3fr 2fr minmax(170px, 20%) minmax(80px, 12%) minmax(80px, 12%);
  --dlist-column-gap: 34px;
  min-width: 820px;

  &-header {
    display: var(--dlist-display);
    grid-template-columns: var(--dlist-columns);
    column-gap: var(--dlist-column-gap);
    align-items: center;
    padding-block: 17px;
    border: 1px solid var(--event-primary);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: var(--event-primary);
    color: var(--event-white);
    font-weight: 700;
  }
}
</style>
