<template>
  <b-dropdown
    ref="dropdown"
    no-caret
    right
    menu-class="event-dropdown-menu"
    toggle-class="event-dropdown-button"
  >
    <template #button-content>
      <icon-filter class="tw-shrink-0" />
      <span class="event-dropdown-button__text tw-truncate">{{ buttonText }}</span>
    </template>
    <div class="event-dropdown-menu__content">
      <div class="event-dropdown-menu__content__title">Sort by</div>
      <div class="event-dropdown-menu__content__item sort">
        <button
          v-for="item in schema.sort"
          class="event-menu-btn"
          :key="item.value"
          :class="{ active: filters.sort === item.value }"
          @click="setSort(item.value)"
        >
          {{ item.label }}
        </button>
      </div>
      <div class="event-dropdown-menu__content__title">Filter by</div>
      <div class="event-dropdown-menu__content__item filter">
        <b-button
          v-for="(btn, idx) in filters.filters"
          :key="idx"
          class="event-menu-btn"
          :pressed.sync="btn.state"
          variant="primary"
          @click="setFilters"
        >
          {{ btn.label }}
        </b-button>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import IconFilter from "@/views/event-page/icons/filter.vue";

export default {
  name: "filter-and-sort",

  components: {
    IconFilter,
  },

  data() {
    return {
      filters: {
        filters: [
          {
            label: "Last Name",
            value: "lastName",
            state: false,
          },
          {
            label: "Removed",
            value: "removed",
            state: false,
          },
          {
            label: "First Name",
            value: "firstName",
            state: false,
          },
          {
            label: "Pinned",
            value: "pinned",
            state: false,
          },
          {
            label: "Published",
            value: "published",
            state: false,
          },
          {
            label: "Donations",
            value: "donations",
            state: false,
          },
          {
            label: "Pending",
            value: "pending",
            state: false,
          },
          {
            label: "Ambassadors",
            value: "ambassadors",
            state: false,
          },
          {
            label: "Online Donation",
            value: "online_donations",
            state: false,
          },
          {
            label: "Offline Donation",
            value: "offline_donations",
            state: false,
          },
        ],
        sort: "",
      },
    };
  },

  methods: {
    hide() {
      this.$refs.dropdown.hide(true);
    },

    setFilters() {
      this.$emit("filters", this.filters);

      // need to recalculate position of the dropdown
      this.$refs.dropdown.hide(true);
      this.$refs.dropdown.show(true);
    },

    setSort(value) {
      this.filters.sort = value;
      this.setFilters();
    },
  },

  computed: {
    buttonText() {
      const sortItem = this.schema.sort.find((item) => item.value === this.filters.sort);
      const activeFilters = this.filters.filters.filter((item) => item.state);
      const filterLabels = activeFilters.map((item) => item.label);
      const filterText = filterLabels.length > 0 ? filterLabels.join(", ") : "Filter";
      return `${sortItem?.label || "Sort"}, ${filterText}`;
    },
    schema() {
      return {
        sort: [
          {
            label: "Newest",
            value: "newest",
          },
          {
            label: "Low-High",
            value: "lowHigh",
          },
          {
            label: "High-Low",
            value: "highLow",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss">
.dropdown {
  --edb-b: 1px solid transparent;
  --edb-bc: var(--event-ui-bg);
  --edb-c: var(--event-soft-dark);

  .event-dropdown-button {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 360px;
    height: auto;
    padding: 18px 28px 18px 22px;
    border-radius: 9999px;
    border: var(--edb-b);
    background-color: var(--edb-bc);
    color: var(--edb-c);
    font-family: var(--font-secondary);
    overflow: hidden;

    &:hover:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled) {
      border: var(--edb-b);
      background-color: var(--edb-bc);
      color: var(--edb-c);
    }

    &__text {
      font-size: 23px;
      line-height: 1;
    }
  }

  &.show {
    .event-dropdown-button.dropdown-toggle {
      border: var(--edb-b);
      background-color: var(--edb-bc);
      color: var(--edb-c);
    }
  }

  .event-dropdown-menu {
    width: max-content;
    padding: 0;
    border-radius: 8px;
    border-color: transparent;
    background-color: var(--event-white);
    color: var(--event-soft-dark);
    font-size: 22px;
    box-shadow: 0px 1.7px 17.2px rgba(0, 0, 0, 0.05);

    &__content {
      padding: 8px 50px 40px;

      &__title {
        margin-top: 36px;
        line-height: 1;
        font-weight: 600;
      }

      &__item {
        align-items: center;
        margin-inline: 5px;
        padding-block: 30px 24px;
        border-bottom: 1px solid var(--event-border);

        &.sort {
          display: flex;
          gap: 34px;
        }

        &.filter {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 32px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .event-menu-btn {
      position: relative;
      width: fit-content;
      height: auto;
      margin: 0;
      padding: 0;
      padding-block: 8px;
      border: none;
      font-family: var(--font-secondary);
      font-size: 22px;
      background-color: transparent !important;
      color: var(--event-soft-dark);

      &:not(:disabled):not(.disabled):active {
        color: var(--event-soft-dark);
      }

      &.active {
        border: none !important;
        color: var(--event-primary) !important;

        &::before {
          content: "";
          position: absolute;
          top: 1px;
          right: -16px;
          bottom: 1px;
          left: -16px;
          border-radius: 9999px;
          background: var(--event-active);
          z-index: -1;
        }
      }
    }
  }
}
</style>
